import React,{ useContext } from 'react';  
import {FicheData} from './QuizData';
import {QuizData} from './QuizData';
import {UserContext} from './Nextcontext';

class PopupFiche extends React.Component { 
    static contextType = UserContext;

    constructor(props){ 
 
        super(props);  
        this.z=[];

        this.state = { 
            title:FicheData[0].title,
            contenu:FicheData[0].fiche,
            qid:FicheData[0].qid,
            questionId:props.questionId,
            ficheId:FicheData[0].id,
            firsttime:true
          
        };  
        this.firstimefich2=true
        this.firstimefich3=true
        this.firstimefich4=true
      }  
    componentWillReceiveProps(props) {
        this.setState({ficheId: props.ficheId});
        this.setState({fiche: props.fiche});
        this.setState({questionId: props.questionId});

    }
    componentDidUpdate(prevProp,prevState){
    //  Open target fiche
        if(this.state.contenu!=prevState.contenu){
            if ((this.state.questionId >= 4)&&(this.state.questionId < 6&&this.firstimefich2)){
                    document.getElementsByClassName('fiche_2')[0].click()
                    this.firstimefich2=false
            }
            else if ((this.state.questionId >= 6)&& (this.state.questionId < 8&&this.firstimefich3)){
                document.getElementsByClassName('fiche_3')[0].click()
                this.firstimefich3=false
            }
            else if (this.state.questionId >= 7 & this.firstimefich4){
                document.getElementsByClassName('fiche_4')[0].click()
                this.firstimefich4=false
            }
        }
    }
    
    componentDidMount(){
        
       
        let vid = this.props.vid
        // let contentsFiche1 = FicheData.filter(el =>el.type === 'fiche1')
        let currentContentsFiche1 = FicheData.filter(el=> el.vid<=vid && el.type === 'fiche1').map(el=>el.fiche).join(' ')
        this.setState({
            title:"Présentation du cas",
            contenu:currentContentsFiche1,
        });
        
        
       
        
        // alert(vid)
        
        /****************first click on tne fiche button ****************** */
            // if (this.state.questionId===1){
            //     // x=FicheData[0].fiche
            // }else if (this.state.questionId===2){
            //     x=FicheData[0].fiche + FicheData[1].fiche
            // }else if (this.state.questionId===3){
            //     x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche
            //     if(this.context.nextsuitechap===0){
            //         x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]
            //     }
            //     if(this.context.nextsuitechap===1){
            //         x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]+FicheData[2].suitefiche[1]
            //     }
            //     if(this.context.nextsuitechap===2){
            //         x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]+FicheData[2].suitefiche[1]
            //     }
            //     if(this.context.nextsuitechap===3){
            //         x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]+FicheData[2].suitefiche[1]
            //     }
            // }else if (this.state.questionId>=3){
            //     x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]+FicheData[2].suitefiche[1]
            // }
            // FicheData.filter(el => el.type === "c" && el.vid <= this.state.vid).join(" ");
            

            /**************** style button changes****************** */

            var  get =document.getElementsByClassName('icon_fiche_1');
          
            get[0].classList.add("icon_onglet_1");
            get[0].classList.remove("icon_onglet_2");
            get[0].classList.remove("icon_onglet_3");
            get[0].classList.remove("icon_onglet_4");


            if (this.state.questionId >=4){
                var target =document.getElementsByClassName('fiche_2');
                target[0].classList.add('fiche_2_click');
            } else{
                var target =document.getElementsByClassName('fiche_2');
                target[0].classList.add('closed_2');
            }
            if (this.state.questionId >=6){
                var target =document.getElementsByClassName('fiche_3');
                target[0].classList.add('fiche_3_click');
            }else{
                var target =document.getElementsByClassName('fiche_3');
                target[0].classList.add('closed_3');
            }
            if (this.state.questionId >=7){
                var target =document.getElementsByClassName('fiche_4');
                target[0].classList.add('fiche_4_click');
            }else{
                var target =document.getElementsByClassName('fiche_4');
                target[0].classList.add('closed_4');
            }

    }

    check(e,contentsFiche2,contentsFiche3,contentsFiche4){
        
      
      /**************first onglet contain******************** */
        if (e.target.id==="fiche_1"){
            document.getElementsByClassName('fiche_1')[0].classList.remove('fiche_fermer')
            document.getElementsByClassName('fiche_2')[0].classList.add('fiche_fermer')
            document.getElementsByClassName('fiche_3')[0].classList.add('fiche_fermer')
            document.getElementsByClassName('fiche_4')[0].classList.add('fiche_fermer')
            //let x;
            // if (this.state.questionId===1){
            //     x=FicheData[0].fiche
            // }else if (this.state.questionId===2){
            //     x=FicheData[0].fiche + FicheData[1].fiche
            // }else if (this.state.questionId>=3){
            //     x=FicheData[0].fiche + FicheData[1].fiche + FicheData[2].fiche+FicheData[2].suitefiche[0]+FicheData[2].suitefiche[1]
            // }
     
            
            let vid = this.props.vid
            let contentsFiche1 = FicheData.filter(el =>el.type==='fiche1')
            let currentContentsFiche1 = contentsFiche1.filter(el=>el.vid<=vid).map(el=>el.fiche).join(' ')

            this.setState({
                title:"Présentation du cas",
                contenu:currentContentsFiche1,
            });


            var  get =document.getElementsByClassName('icon_fiche_1');
          
            get[0].classList.add("icon_onglet_1");
            get[0].classList.remove("icon_onglet_2");
            get[0].classList.remove("icon_onglet_3");
            get[0].classList.remove("icon_onglet_4");

            var  get1 =document.getElementsByClassName('icon_fiche_4_1');
          
            get1[0].classList.remove("icon_onglet_4_1");

            var  get2 =document.getElementsByClassName('icon_fiche_4_2');
           
            get2[0].classList.remove("icon_onglet_4_2");
        }






      /**************second onglet contain******************** */

        if ((e.target.id==="fiche_2") && (this.state.questionId >= 4)) {

            this.setState({
                title:"Examens cliniques",
                contenu:contentsFiche2.join(''),
            });
            
             var  get =document.getElementsByClassName('icon_fiche_1');
           
             get[0].classList.add("icon_onglet_2");
             get[0].classList.remove("icon_onglet_1");
             get[0].classList.remove("icon_onglet_3");
             get[0].classList.remove("icon_onglet_4");
             document.getElementsByClassName('fiche_1')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_2')[0].classList.remove('fiche_fermer')
             document.getElementsByClassName('fiche_3')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_4')[0].classList.add('fiche_fermer')

             var  get1 =document.getElementsByClassName('icon_fiche_4_1');
            
             get1[0].classList.remove("icon_onglet_4_1");

             var  get2 =document.getElementsByClassName('icon_fiche_4_2');
          
             get2[0].classList.remove("icon_onglet_4_2");

        }
      /**************third onglet contain******************** */

        if ((e.target.id==="fiche_3")&& (this.state.questionId >= 6)){
           
        //    if(this.state.questionId>=7){
        //     // tableau_comp.indexOf(FicheData[7].fiche) === -1 ? tableau_comp.splice(1, 0,FicheData[7].fiche) : console.log("This item already exists");
        //    }
               
                 
            this.setState({
                title:"Agents pathogènes",
                contenu:contentsFiche3.join(''),
            });
    
            
           
             var  get =document.getElementsByClassName('icon_fiche_1');
            
             get[0].classList.add("icon_onglet_3");

             get[0].classList.remove("icon_onglet_1");
             get[0].classList.remove("icon_onglet_2");
             get[0].classList.remove("icon_onglet_4");

             var  get1 =document.getElementsByClassName('icon_fiche_4_1');
            
             get1[0].classList.remove("icon_onglet_4_1");

             var  get2 =document.getElementsByClassName('icon_fiche_4_2');
             
             get2[0].classList.remove("icon_onglet_4_2");
             document.getElementsByClassName('fiche_1')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_2')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_3')[0].classList.remove('fiche_fermer')
             document.getElementsByClassName('fiche_4')[0].classList.add('fiche_fermer')

        }
      /**************forth onglet contain******************** */

        if ((e.target.id==="fiche_4")&& (this.state.questionId >= 7)){
            
            this.setState({
                title:"Prise en charge",
                contenu:contentsFiche4.join(''),
               
            });
           
            // if (this.state.questionId>=9){
            //     tableau_prise.indexOf(FicheData[10].fiche) === -1 ? tableau_prise.splice(2, 0, FicheData[10].fiche) : console.log("This item already exists");
               
            // }  if( this.state.questionId>=10){
            //     console.log('ouiiiiii10000')
            //     tableau_prise.indexOf(FicheData[12].fiche) === -1 ? tableau_prise.splice(4, 0, FicheData[12].fiche) : console.log("why not");
               
            // } if( this.state.questionId>=14){
            //     tableau_prise.indexOf(FicheData[14].fiche) === -1 ? tableau_prise.splice(6, 0, FicheData[14].fiche) : console.log("This item already exists");
            //     tableau_prise.indexOf(FicheData[15].fiche) === -1 ? tableau_prise.splice(8, 0, FicheData[15].fiche) : console.log("This item already exists");
            // }
          

            var  get =document.getElementsByClassName('icon_fiche_1');
            
            get[0].classList.add("icon_onglet_4");

             get[0].classList.remove("icon_onglet_1");
             get[0].classList.remove("icon_onglet_2");
             get[0].classList.remove("icon_onglet_3");




             var  get1 =document.getElementsByClassName('icon_fiche_4_1');
           
             get1[0].classList.add("icon_onglet_4_1");

             var  get2 =document.getElementsByClassName('icon_fiche_4_2');
             
             get2[0].classList.add("icon_onglet_4_2");
             document.getElementsByClassName('fiche_1')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_2')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_3')[0].classList.add('fiche_fermer')
             document.getElementsByClassName('fiche_4')[0].classList.remove('fiche_fermer')
        }
        get=[];
  }




  render() {  
    return (  
        <UserContext.Consumer>
        { value => ( 
      <div className='popupfiche'>  
        <div className="grid">
            <div className="fiche_choix fiche_1 "             id="fiche_1" onClick={(e)=>this.check(e, value.contentsFiche2, value.contentsFiche3, value.contentsFiche4)}> </div>
            <div className="fiche_choix fiche_2 fiche_fermer" id="fiche_2" onClick={(e)=>this.check(e, value.contentsFiche2, value.contentsFiche3, value.contentsFiche4)}>  </div>
            <div className="fiche_choix fiche_3 fiche_fermer" id="fiche_3" onClick={(e)=>this.check(e, value.contentsFiche2, value.contentsFiche3, value.contentsFiche4)}> </div>
            <div className="fiche_choix fiche_4 fiche_fermer" id="fiche_4" onClick={(e)=>this.check(e, value.contentsFiche2, value.contentsFiche3, value.contentsFiche4)}> </div>
        </div>

        <div className='popup_inner_fiche'>  
            <div className='fiche_contain'>
          
                <div className='title' dangerouslySetInnerHTML={{ __html:this.state.title}}></div>
                <div className='contenu'  dangerouslySetInnerHTML={{ __html:this.state.contenu}}></div>   
                <div className="icon_fiche_1 icon_onglet_1 icon_onglet_2 icon_onglet_3 icon_onglet_4 "></div>
                 <div className="icon_fiche_4_1 "></div>
                <div className="icon_fiche_4_2 "></div>

            </div>
        </div>  
        <div className="close_fiche" onClick={this.props.closePopup}></div>  

      </div>  
       )}
    </UserContext.Consumer>
    );  

  }  
}  

export default PopupFiche;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { prototype } from 'stream';



class Question extends Component {  

  constructor(props){  
    super(props);  
  }  

  render() {  
    // console.log("question",this.props.current,this.props.id);
    // console.log(this.props) ;
    return (  
      <div className="question " dangerouslySetInnerHTML={{ __html:this.props.question}}></div>
    )}


} 
export default Question;


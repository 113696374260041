import React, {Component} from 'react';
import {QuizData} from './QuizData';
import Questions from './Questions';
import Next from './Next';
import Fiche from './Fiche';
import Sound from './Sound';
import Menu from './Menu';
import Chapitrefin from './Chapitrefin';
import {UserContext} from './Nextcontext';
import Chapitre1 from './Chapitre1';
import Info from './Info';
import Ref from './Ref';
import * as images from "../assets";
//import images from '../assets/fiche.png';
class Check extends Component {
    constructor(props) {
      super(props);
      this.background= null;
      this.state = {
        questionId:1,    
        question: '',
        options: [],
        answer: '',
        answers:[],
        info:[],
        infoglobal:[],
        suite:[],
        questionsuite:"",
        type:'',
        incrementation:0,
        bg:'',
        reference:[],
        comptinfo:-1,
        cmpt:-1,
        toutref:"",
        chapitre:0,
        splash_screen:true,
        progress: 0,
        suiteId:0,
        suivant:false,

  
      };

      this.gonext = this.nextQuestionHandler.bind(this);
      this.increment=  this.increment.bind(this);
    }
   
  incrementSuiteId=()=>{
    this.setState({ suiteId:this.state.suiteId+1 })
  }
  


    /**********load question with options*********** */
    LoadQuiz = () => {
        const {questionId}= this.state;
        this.setState(()=> {
            return{
                question:QuizData[questionId].question,
                options:QuizData[questionId].options,
                answers:QuizData[questionId].answer,
                info:QuizData[questionId].info,
                infoglobal:QuizData[questionId].infoglobal,
                suite:QuizData[questionId].suite,
                type:QuizData[questionId].type,
                bg:QuizData[questionId].bg,
                reference:QuizData[questionId].reference,
                toutref:QuizData[questionId].toutref,
                chapitre:QuizData[questionId].chapitre,
                suivant:QuizData[questionId].suivant,
                rejouer:QuizData[questionId].suivant

            };
  
        })
    }
    

    componentWillMount(){
        this.LoadQuiz();
        this.changebg()
    };

  /**********change background*********** */

    changebg(){
      if(this.state.bg=='medecin')
        return('medecin image')

      
        else if(this.state.bg=='infirmiere')
        return('infirmiere image')
        
        else if(this.state.bg=='medecinzoome')
        return('medecinzoome image')
       
        else if(this.state.bg=='medecinzoomeBG')
        return('medecinzoomeBG image')
        
        
    };
  /**********change background*********** */
    nextQuestionHandler=()=> {
        const{questionId}=this.state;
        const{rejouer}=this.state
        if(rejouer){window.location.reload(false) }
        else this.setState({
          questionId:questionId+1,
          answer:'',
          check : false,
          incrementation:0,    
        });
        this.setState(prevState => ({
          i: 1,   // wtf ?
       }));
       

    };
      
      componentDidUpdate(prevProp,prevState){
       
      const {questionId} = this.state;
        if(this.state.questionId !== prevState.questionId){
            this.setState(()=> {
                return{
                    disabled:true,
                    question:QuizData[questionId].question,
                    options:QuizData[questionId].options,
                    answers:QuizData[questionId].answer,
                    info:QuizData[questionId].info,
                    infoglobal:QuizData[questionId].infoglobal,
                    type:QuizData[questionId].type,
                    suite:QuizData[questionId].suite,
                    bg:QuizData[questionId].bg,
                    reference:QuizData[questionId].reference,
                    toutref:QuizData[questionId].toutref,
                    chapitre:QuizData[questionId].chapitre,
                    comptinfo:-1,
                    suivant:QuizData[questionId].suivant,
                    rejouer:QuizData[questionId].rejouer
                };
            }) 
        }

      } 
      componentDidMount() {
        var src =[]
        for (const key in images) {
          if (Object.hasOwnProperty.call(images, key)) {
            src.push(images[key]) ;
           
          }}
    
              
        const addState =  (val) =>  {
         
          //without splashscreen
          // this.setState((prevState, props) => ({
          //   splash_screen: false
    
          // }));


          return () =>  {
                
                  this.setState((prevState, props) => ({
                  progress: prevState.progress + val ,
                
                }));
            if (this.state.progress == 100){
            
                    this.setState((prevState, props) => ({
                      splash_screen: false
              
            }));
          }
        }; 
          
        }
        
        document.addEventListener("DOMContentLoaded", function(event) {
          // - Code to execute when all DOM content is loaded. 
          // - including fonts, images, etc.
          // this.background=document.querySelectorAll(".image").length;
          
          // console.log("src-------------");
          // console.log(src);
         
          window.loadIMage(src , 
          
            addState(  ( 1 / src.length) * 100))

          })

          
      }
/*******************return information related with option *********************** */
      infobtn=(cmpt)=>{
        this.setState({
          comptinfo: cmpt, 
          });
      }


/***********reload the quiz************** */

      reload() {
        window.location.reload();
        }

/*incrementation for suite in bubble if suite */
increment(incrementation){
  this.setState({
    incrementation: incrementation, 
    });
}
  render() {
    const vid = this.state.questionId+this.state.suiteId 
    const {comptinfo,incrementation,type,questionsuite,suite,infoglobal,info,question ,answer,options,questionId,answers,reference,suivant,rejouer} = this.state;
    return (
          /**********use context ***********/
          <UserContext.Consumer> 
          { value => ( 

            <div className="App">
              {/* <div style={{position : "absolute",left:"20%"}}>{'id :'}{this.state.questionId}{'   '}{'vid :'}{vid}   </div> */}
{/************************splash screen***************************/} 
              
               {  
                   this.state.splash_screen===true &&
                   <div className="splash image">
                  
                     <div className="myProgress">
                         <div id="myBar" style={{width: this.state.progress + "%"}}>

                           <div class='loading'><span class="load">{ Math.trunc(this.state.progress) + "%"}</span></div>
                               </div>
                              
                     </div>

                       </div>

                }
{/************************progress indicator  ***************************/} 

                { 
                  this.state.splash_screen==false && questionId!=11&&
                  <div>
                  <Menu questionId={this.state.questionId}   chapitre={this.state.chapitre} 
                   /> 
                   
                   </div>
                }
{/************************Chapiter 1 Introduction ***************************/} 

               { (questionId in [0,1,2,3]) && this.state.splash_screen==false &&
//Here change BG
                <div className= {this.changebg()}>
                      <div>
                        <Chapitre1  questionId ={questionId}
                                    suite ={suite}
                                    questionsuite={questionsuite}
                                    question ={question}
                                    incrementation={incrementation}
                                    gonext={this.gonext}
                                    chapitre={this.state.chapitre}
                                    splash_screen={this.state.splash_screen}
                                    isQuestion = {this.state.options.length >= 1}
                                    incrementSuiteId={this.incrementSuiteId}
                                    vid={vid}
                                    suivant={suivant}
                                    increment={this.increment}/>

                      </div>

               
                  </div>
                  

                }
{/************************other chapter questions ***************************/} 

                { questionId >= 4 &&  
              
                    //  questionId != 15  &&  questionId != 12 &&   
                    <div className= {`image ${this.changebg()}`}>  
                        <Questions
                                answer ={answer} 
                                options ={options}
                                question ={question}
                                questionId ={questionId} 
                                answers ={answers} 
                                info={info}
                                infoglobal={infoglobal}
                                suite={suite}
                                type ={type} 
                                incrementation={incrementation}
                                gonext={this.gonext}
                                reference={reference}
                                infobtn={this.infobtn}
                                chapitre={this.state.chapitre}
                                isQuestion = {this.state.options.length >= 1}
                                incrementSuiteId={this.incrementSuiteId}
                                vid={vid}
                                rejouer={rejouer}
                                increment={this.increment}

                                />  
                  
                        {/************************chapters with Ref ***************************/} 
                        
                        {/* {console.log("Check :: this.state.infoglobal = ",this.state.infoglobal)} */}
                        <Info  comptinfo={comptinfo}
                        infoglobal={this.state.infoglobal}
                        index={value.index}
                        toutref={this.state.toutref} 
                        questionId ={questionId} 
                        answers = {this.state.answers}
                        incrementation={incrementation}
                        />
                        <Fiche  questionId={questionId}
                        suite={this.state.suite}
                        question={question}
                       vid={vid}/>  
                        

                    </div >   
                }  
             

             
                <Next gonext={this.nextQuestionHandler}
                      questionId={questionId}/>
                  
                  { 
                  this.state.splash_screen==false && 
                  
                   <Sound/>
                  }
                        </div>
                      )}
             
        </UserContext.Consumer>
      );

    }
  }
    
    export default Check;


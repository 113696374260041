//info : info in bubble when click
//infoglobal : info in Info Button
//toutref : all infoglobal in Info Button

export const QuizData = [
  {
    id: 0,
    chapitre: 0,
    type: '',
    bg: 'medecinzoomeBG',
    question: `Bienvenue Docteur. Jouez avec « Cas clinique » et trouvez le bon diagnostic et le traitement le plus adapté pour guérir votre patient !`,
    suite: [],
    answer: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: "",
  },
  {
    id: 1,
    chapitre: 0,
    type: '',
    bg: 'medecinzoome',
    question: `Bonjour Docteur, je m’appelle Nassim. J’ai 45 ans
    avec 15 ans de carrière en tant que cuisinier.
    Pour être honnête, depuis 10 ans je fume un
    paquet de cigarettes par jour.`,
    suite: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: [],

  },
  {
    id: 2,
    chapitre: 0,
    type: '',
    bg: 'medecinzoome',
    reference: [],
    toutref: "",
    question: `À ce que je sache, aucun membre de ma famille
    n’est asthmatique ni allergique.`,
    suite: [
      `Aujourd’hui, je viens vous voir car je deviens très
    essoufflé. Depuis quelques mois, tout effort
    physique est devenu gênant et il faut que je
    m’arrête pour reprendre mon souffle.`,

    ],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
  },
  {
    suivant: true,
    id: 3,
    chapitre: 0,
    type: '',
    bg: 'medecinzoome',
    question: `En plus, je tousse plus fréquemment ces derniers
    jours et depuis hier, mes crachats sont devenus
    épais et jaunâtres.`,
    suite: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: [],
  },
  {
    id: 4,
    chapitre: 1,
    type: 'click',
    bg: 'infirmiere',
    reference: [],
    toutref: "",
    answer: [],
    clinique: [],
    question: `Docteur, en cliquant sur les différents examens, vous
    pouvez consulter les résultats.`,
    suite: [],
    options: [`INSPECTION`, `GAZ SU SANG`, `RADIOGRAPHIE DU THORAX`, `SPIRROMÉTRIE`, `AUSCULTATION`],

    info: [
      `L’inspection met en évidence une respiration à lèvres
     pincées, avec la présence du signe de Campbell.`,

      `La gazométrie artérielle de Nassim est correcte, en
    dehors d'une élévation légère des bicarbonates.`,

      `La radiographie thoracique montre des signes de
    distension thoracique modérée surtout au niveau
    des sommets.`,

      `Nassim présente un VEMS prédit de 45% et un
    rapport de VEMS/CVF=0,6 après administration de
    bronchodilatateur.`,

      `À l’auscultation pulmonaire, Nassim présente une
    diminution du murmure vésiculaire avec des râles
    sibilants surajoutés. Son rythme cardiaque est
    régulier.`],


    infoglobal: [
      `<div class='titre'>Inspection</div>
    <div class='info-popup'> 
    La respiration à lèvres pincées est une technique qui peut aider, lorsque un patient souffrant d'une
maladie pulmonaire chronique est essoufflé en pratiquant une activité physique.<sup>[1]</sup>
Le signe de Campbell s'observe dans les syndromes obstructifs respiratoires. Il est défini par la
descente inspiratoire vers le thorax du cartilage thyroïde et une diminution du segment sus-sternal de
la trachée qui varie normalement entre 3 et 5 cm.<sup>[2]</sup>
Le signe de Campbell est corrélé à la sévérité du syndrome obstructif.<sup>[2]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b> Fondation santé pulmonaire. Respiration à lèvres pincées. [en ligne, consulté le 13/07/2021].</br>
    Disponible sur :  <a target="_blank" href="https://lunghealth.ca/wp-content/uploads/2021/06/FR-2021-Pursed-Lip-Breathing-Poster-Infographic.pdf">https://lunghealth.ca/wp-content/uploads/2021/06/FR-2021-Pursed-Lip-Breathing-Poster-Infographic.pdf</a></br>
    <b>2.</b> Campbell. [En ligne, consulté le 13/07/2021].</br>
    Disponible sur : <a target="_blank" href="http://www.ammppu.org/semeio/campbell.htm">http://www.ammppu.org/semeio/campbell.htm</a>
    </div>
   `
      ,

      `<div class='titre'>Gaz du sang</div>
    <div class='info-popup'> 
    La réalisation de gaz du sang est indispensable pour porter le diagnostic d’insuffisance respiratoire
    chronique (IRC). Le seuil de 70 mmHg (9,3 kPa) de PaO2 mesuré en air ambiant, au repos et à l’état
    stable, est retenu pour parler d’insuffisance respiratoire chronique, quel que soit le niveau de
    PaCO2.<sup>[6]</sup> </br>
    </div>

    <div class ='reference'>
    <b>PaO2 :</b>pression partielle d'oxygène dans le sang artériel.</br>
    <b>PaCO2 :</b>pression partielle de dioxyde de carbone dans le sang artériel.</br>
    <div class='sous_titre2'> Références </div> 
    <b>6.</b> CEP : Collège des enseignants de pneumologie. Item 204 : Insuffisance respiratoire chronique. [En ligne, consulté le 13/07/2021].</br>
    Disponible sur :  <a target="_blank" href="https://splf.fr/wp-content/uploads/2018/12/Insuffisance-respiratoire-chronique.pdf">https://splf.fr/wp-content/uploads/2018/12/Insuffisance-respiratoire-chronique.pdf</a>
  
  
   `
      ,

      `<div class='titre'>Radiographie du thorax</div>
    <div class='info-popup'> 
    <b>Signes de distension thoracique modérée :<sup>[5]</sup></b></br>
    - Horizontalisation des côtes</br>
- Aplatissement des coupoles diaphragmatiques</br>
- Incisure médio thoracique : coup de hache externe</br>
- Elargissement des espaces clairs rétro sternal et rétro cardiaque sur le cliché de profil </br>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>5.</b> Cours bronchopneumopathie chronique obstructive. [En ligne, consulté le 18/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://www.medecinesfax.org/useruploads/files/9-BPCO%202020.pdf">https://www.medecinesfax.org/useruploads/files/9-BPCO%202020.pdf</a>
    </div>
   `
      ,

      `<div class='titre'>Spirométrie</div>
    <div class='info-popup'> 
    Le diagnostic de trouble ventilatoire obstructif est fondé sur la spirométrie avec la mesure du volume
expiratoire maximal à la première seconde (VEMS) et de la capacité vitale forcée (CVF).
Le trouble ventilatoire obstructif est défini par un rapport VEMS/CVF < 70 % après administration d’un
bronchodilatateur.<sup>[4]</sup>
    </div>
    
    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>4.</b> SPLF. Recommandation pour la pratique clinique. Prise en charge de la BPCO. Revue des Maladies Respiratoires. 2017 ; 27 : 522-48.
    </div>`,


      `<div class='titre'>Auscultation</div>
    <div class='info-popup'> 
    La diminution du murmure vésiculaire est retrouvée de manière bilatérale dans l’emphysème
    pulmonaire traduisant une hypoventilation alvéolaire.[3]
    Les râles bronchiquessont dus à la vibration de l’air dans des bronches enflammées ou rétrécies et dont
    le timbre dépend du calibre des bronches d’où ils naissent (ronflants dits «ronchi» de timbre grave
    dans les gros troncs bronchiques,sibilantsde timbre aigu dans les bronches de plus petit calibre). Ce
    sont des râles secs entendus aux deux temps respiratoires mais plus nets à l’expiration.<sup>[3]</sup>
    </div>
    
    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>3.</b> Cours. [En ligne, consulté le 18/03/2021].</b>
    Disponible sur : <atarget="_blank" href="http://campus.cerimes.fr/semiologie-cardiologique/enseignement/examenclinique/site/html/4.html">http://campus.cerimes.fr/semiologie-cardiologique/enseignement/examenclinique/site/html/4.html</a>.
    </div>`
    ],
  },
  {
    id: 5,
    chapitre: 2,

    type: 'qcm',
    suite: [`Bravo Docteur ! Nassim non connu atteint de BPCO
    mais présentant un tableau d’infection respiratoire
    basse avec une aggravation des symptômes
    respiratoires confirme qu’il souffre d'exacerbation
    aiguë de BPCO.`],
    bg: 'medecin',
    reference: [],

    question: `D’après vous docteur, quel est le diagnostic à retenir <span class='interogation'>?</span>`,
    options: [`Insuffisance cardiaque congestive`, `Bronchectasie`, `Décompensation d’asthme`, `Exacerbation aiguë de BPCO`],
    answer: [`Exacerbation aiguë de BPCO`],
    info: [
      `En fait Docteur, les signes et les examens cliniques
    ne sont pas en faveur d’une insuffisance cardiaque
    congestive.`,
      `En fait Docteur, l’examen radiologique exclue le
    diagnostic de bronchectasie.`,
      `En fait Docteur, la survenue de symptomes après
    40 ans, les signes de distension thoracique
    modérée présentes dans la radiographie du thorax
    et le rapport de VEMS/CVF=0,6 après
    administration de bronchodilatateur, excluent le
    diagnostic d'une décompensation d'asthme.`,
      `En effet, Docteur, le diagnostic à retenir est celui
    d'exacerbation aiguë de BPCO.`],
    infoglobal: [
      `<div class='titre'>Insuffisance cardiaque congestive</div>
    <div class='info-popup'>
    <b>En cas d'insuffisance cardiaque congestive on note :<sup>[8]</sup></b>
    - Sensation de fatigue et de faiblesse
    - Gain de poids attribuable à la rétention de liquide
    - Essoufflement, même lors d'activités légères ou pendant le sommeil
    - Enflure des jambes, des chevilles ou de l'abdomen
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>8.</b> L’insuffisance cardiaque. Institut de cardiologie de l’Université d’Ottawa. [En ligne, consulté le 13/07/2021].</b>
    Disponible sur:<a  target="_blank" href="https://www.ottawaheart.ca/sites/default/files/images/Patients-Visitors/Symptoms-Posters/heart-failure-symptoms-fr.png"> https://www.ottawaheart.ca/sites/default/files/images/Patients-Visitors/Symptoms-Posters/heart-failure-symptoms-fr.png</a></br>
    </div>
   `
      ,

      `<div class='titre'>Bronchectasie </div>
    <div class='info-popup'>
    En cas de bronchectasie, la radiographie thoracique montre une dilatation des bronches et un
    épaississement de la paroi bronchique.<sup>[7]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    Global Initiative for Chronic Obstructive Lung Disease (GOLD). Global strategy for the diagnosis, management and prevention of</br>
    chronic obstructive pulmonary disease: 2020 report. [En ligne, consulté le 18/03/2021].</br>
    Disponible sur : <a  target="_blank" href="https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf">https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf</a>
    </div>
    `
      ,

      `<div class='titre'>Décompensation d’asthme</div>
    <div class='info-popup'>
    Diagnostic de l’asthme, de la BPCO et du chevauchement asthme-BPCO selon GOLD (2017) :<sup>[9]</sup>
    </br>
    </br>
    <div class="grid_3_8">
   
    <div class="red">Feature</div>
    <div>More likely to be asthma
    if several of …*</div>
    <div>More likely to be COPD if
    several of …*</div>


    <div class="red">Age of onset</div>
    <div>Onset before age 20 years</div>
    <div>Onset before age 40 years</div>

  
    <div class="red">Pattern
    of respiratory
    symptoms</div>
    <div>- Variation in symptoms over
    minutes, hours or days
    - Symptoms worse during
    the night or early morning
    - Symptoms triggered by
    exercise, emotions including
    laughter, dust or exposure
    to allergens</div>
    <div>- Persistence of symptoms
    despite treatment
    - Good and bad but always
    daily symptoms
    and exertional dyspnea
    - Chronic cough and sputu
    preceded onset of dyspnea,
    unrelated to triggers</div>

    <div class="red">Lung function</div>
    <div>Record of variable airflow
    limitation (spirometry,
    peak flow)</div>
    <div>Record of persistent airflow
    limitation (post-bronchodilator
    FEV1/FVC<0.7)</div>

    <div class="red">Lung function
    between
    symptoms</div>
    <div>Lung function normal
    between symptoms</div>
    <div>Lung function abnormal
    between symptoms</div>

    <div class="red">Past history or
    family history</div>
    <div>- Previous doctor diagnosis
    of asthma
    - Family history of asthma,
    and other allergic conditions
    (allergic rhinitis or eczema)</div>
    <div>- Previous doctor diagnosis
    of COPD, chronic bronchitis
    or emphysema
    - Heavy exposure to a risk
    factor: tobacco smoke,
    biomass fuels</div>

    <div class="red">Time course</div>
    <div>- No worsening of symptoms
    over time. Symptoms vary
    either seasonally, or from
    year to year
    - May improve spontaneously
    or have an immediate
    response to BD or to ICS
    over weeks</div>
    <div>- Symptoms slowly
    worsening over time
    (progressive course over
    years)
    - Rapid-acting bronchodilator
    treatment provides only
    limited relief</div>

    <div class="red">Chest X-ray</div>
    <div>Normal</div>
    <div>Severe hyperinflation</div>
    </div>
    </br>
    *Syndromic diagnosis of airways disease: how to use Box 2B</br>
    Shaded columns list features that, when present, best identify patients with typical asthma and typical
    COPD. For a patient, count the number of check boxes in each column. If three or more boxes are
    checked for either asthma or COPD, the patient is likely to have that disease. If there are similar
    numbers of checked boxes in each column, this is described as asthma-COPD overlap.

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>9.</b>Global Initiative for Chronic Obstructive Lung Disease (GOLD). Diagnosis and initial treatment of asthma, COPD and asthma - COPD
    overlap 2017. [En ligne, consulté le 16/07/2021].
    Disponible sur : <a target="_blank" href="https://ginasthma.org/wp-content/uploads/2019/11/GINA-GOLD-2017-overlap-pocket-guide-wms-2017-ACO.pdf">https://ginasthma.org/wp-content/uploads/2019/11/GINA-GOLD-2017-overlap-pocket-guide-wms-2017-ACO.pdf</a>
    </div>
   `
      ,

      `<div class='titre'>Exacerbation aiguë de BPCO</div>
    <div class='info-popup'>
    L’exacerbation est définie par une majoration des symptômes respiratoires au-delà des variations
    quotidiennes (en pratique, d’une durée de  48h ou justifiant une modification thérapeutique).
    Les critères couramment utilisés sont l’augmentation de la dyspnée, de la toux, du volume de
   l’expectoration ou la modification de l’expectoration (aspect purulent).<sup>[10]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>10.</b> SPLF. Recommandation pour la pratique clinique. Prise en charge de la BPCO. Revue des Maladies Respiratoires. 2010 ; 27 : 522-48.
    </div> 
   ` ],

    toutref: [`<div class='titre'>Diagnostic</div>
    <div class='info-popup'>
    En cas de bronchectasie, la radiographie thoracique montre une dilatation des bronches et un
    épaississement de la paroi bronchique.<sup>[7]</sup></br>
    <b>Les signes d’une insuffisance cardiaque congestive sont :<sup>[8]</sup></b></br>
    - Sensation de fatigue et de faiblesse</br>
    - Gain de poids attribuable à la rétention de liquide</br>
    - Essoufflement, même lors d'activités légères ou pendant le sommeil</br>
    - Enflure des jambes, des chevilles ou de l'abdomen</br>
    </br>
    </br>
    L’exacerbation est définie par une majoration des symptômes respiratoires au-delà des variations
    quotidiennes (en pratique, d’une durée de  48h ou justifiant une modification thérapeutique).
    Les critères couramment utilisés sont l’augmentation de la dyspnée, de la toux, du volume de
    l’expectoration ou la modification de l’expectoration (aspect purulent).<sup>[10]</sup>
    </div>

    <div class ='reference'>
    <b>BPCO :</b> Bronchopneumopathie chronique obstructive.
    <div class='sous_titre2'> Références </div> 
    <b>7.</b> Global Initiative for Chronic Obstructive Lung Disease (GOLD). Global strategy for the diagnosis, management and prevention of</br>
    chronic obstructive pulmonary disease: 2020 report. [En ligne, consulté le 18/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf">https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf</a></br>
    <b>8.</b> L’insuffisance cardiaque. Institut de cardiologie de l’Université d’Ottawa. [En ligne, consulté le 13/07/2021].</br>
    Disponible sur:</br>
    <a target="_blank" href="https://www.ottawaheart.ca/sites/default/files/images/Patients-Visitors/Symptoms-Posters/heart-failure-symptoms-fr.png">https://www.ottawaheart.ca/sites/default/files/images/Patients-Visitors/Symptoms-Posters/heart-failure-symptoms-fr.png</a></br>
    <b>10.</b> SPLF. Recommandation pour la pratique clinique. Prise en charge de la BPCO. Revue des Maladies Respiratoires. 2010 ; 27 : 522-48.  
    </div>`],
  },


  {
    id: 6,
    chapitre: 3,
    type: 'qcm',
    bg: 'medecin',
    reference: [],
    question: `Parmi ces micro-organismes, lesquels peuvent être
    responsables d’exacerbation aiguë de BPCO <span class='interogation'>?</span>`,
    suite: [`Bravo ! <i>Streptococcus pneumoniae</i>,
    <i>Haemophilus influenzae</i> et <i>Moraxella catarrhalis</i>
    sont les micro-organismes prédominants chez les
    patients présentant des exacerbations aiguës de
    BPCO d’origine bactérienne.`],
    options: [`<i>Streptococcus pneumoniae</i>`, `<i>Haemophilus influenzae</i>`, `<i>Moraxella catarrhalis</i>`, 'Rhinovirus'],
    answer: [`<i>Streptococcus pneumoniae</i>`, `<i>Haemophilus influenzae</i>`, `<i>Moraxella catarrhalis</i>`],
    info: [
      `En effet Docteur,<i> Streptococcus pneumoniae</i> est un micro-organisme prédominant chez les patients
    présentant une exacerbation aiguë de BPCO.`,
      `En effet Docteur, <i>Haemophilus influenzae</i> est un
    micro-organisme prédominant chez les patients
    présentant une exacerbation aiguë de BPCO.`,
      `En effet Docteur, <i> Moraxella catarrhalis</i> est un
    micro-organisme prédominant chez les patients
    présentant une exacerbation aiguë de BPCO.`,
      `En fait Docteur, le Rhinovirus peut être responsable
    d’exacerbation aiguë de BPCO, sauf que la
    présence, dans ce cas, d'expectorations purulentes
    est en faveur d’une origine bactérienne
    d’exacerbation aiguë de BPCO.`],
    infoglobal: [
      `<div class='titre'><i>Streptococcus pneumoniae</i></div>
    <div class='info-popup'>
    <i>Streptococcus pneumoniae</i> joue un rôle mineur dans la maladie stabilisée, mais constitue une autre
    cause majeure d‘exacerbation aiguë de BPCO étant impliqué jusqu'à un cas sur 7.<sup>[12]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>12.</b>Sethi S, Murphy TF. Infection in the Pathogenesis and Course of Chronic Obstructive Pulmonary Disease. N Engl J Med. 2008;359:2355-65.
    </div>
   `
      ,

      `<div class='titre'><i>Haemophilus influenzae</i></div>
    <div class='info-popup'>
    <i>Haemophilus influenzae</i> est l’un des principaux agents pathogènes des exacerbations aiguës de
    BPCO retrouvé dans 20-30% des exacerbations.<sup>[12]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>12.</b>Sethi S, Murphy TF. Infection in the Pathogenesis and Course of Chronic Obstructive Pulmonary Disease. N Engl J Med. 2008;359:2355-65.
    </div>
   `
      ,

      `<div class='titre'><i>Moraxella catarrhalis</i></div>
    <div class='info-popup'>  
    <i>Moraxella catarrhalis</i> constitue une autre cause majeure d‘exacerbation aiguë de BPCO, étant
    impliqué dans 10-15% des exacerbations.<sup>[12]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>12.</b> Sethi S, Murphy TF. Infection in the Pathogenesis and Course of Chronic Obstructive Pulmonary Disease. N Engl J Med. 2008;359:2355-65.
    </div>
   `
      ,

      `<div class='titre'>Rhinovirus</div>
    <div class='info-popup'>
    Les rhinovirus sont les virus responsables du rhume banal et comme les entérovirus, ils font partie du
    groupe des picornavirus à ARN. Ils se transmettent par voie aérienne, via des gouttelettes et des
    sécrétions nasopharyngées en suspension ou par contact direct. Ils infectent les cellules de l’épithélium
    respiratoire principalement par leurs récepteurs spécifiques ICAM-1. Les patients atteints de BPCO
    sont plus sensibles à ce type d’infection virale, qui constitue un facteur déclenchant d'exacerbations
    aiguës de BPCO.<sup>[11]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>11.</b> Kherad O, Bridevaux PO, Kaiser L, Janssens JP, Rutschmann O. Rôle des infections virales dans les exacerbations de</br>
    bronchopneumopathie chronique obstructive (BPCO). Rev Med Suisse. 2011 16;7(317):2222-6.
    </div>
   `],
    toutref: [
      `<div class='titre'>Pathogènes en cause</div>
    <div class='info-popup'>
    La plupart des exacerbations aiguës de BPCO sont causées par une infection virale ou bactérienne.
    Les germes les plus fréquemment en cause sont <i>Hæmophilus influenzae</i>, </i>Moraxella catarrhalis</i>,
    <i>Streptococcus pneumoniae</i> et plus rarement, <i>Pseudomonas æruginosa</i> et les entérobacteries.<sup>[11]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>11.</b>Kherad O, Bridevaux PO, Kaiser L, Janssens JP, Rutschmann O. Rôle des infections virales dans les exacerbations de
    bronchopneumopathie chronique obstructive (BPCO). Rev Med Suisse. 2011 16;7(317):2222-6. 
    </div>
   `],
  },


  {
    id: 7,
    chapitre: 4,
    type: 'qcm',
    bg: 'medecin',
    reference: [],
    question: `Docteur, quelles stratégies thérapeutiques recommanderiez-vous pour traiter Nassim <span class='interogation'>?</span>`,
    suite: [`Bravo Docteur, Le traitement des exacerbations est
    celui des symptômes (bronchodilatateurs) et de la
    cause (antibiotiques devant une expectoration
    purulente). Il associe aussi des corticostéroïdes
    oraux avec une oxygénothérapie.`],
    options: [`Oxygénothérapie`, `Hospitalisation`, `Bronchodilatateurs`, `Corticostéroïdes`, 'Antibiothérapie'],
    info: [
      `En effet Docteur, l’oxygénation est un traitement
      complémentaire pour le traitement des
      exacerbations aiguës de BPCO.`,

      `En fait Docteur, l’hospitalisation en cas
      d’exacerbation aiguë de BPCO n’est pas
      automatique, la prise en charge de Nassim dans ce
      cas peut se faire en ambulatoire.`,

      `En effet Docteur, les bronchodilatateurs
      représentent un traitement essentiel, à administrer
      exclusivement par voie inhalée.`,

      `En effet Docteur, l’efficacité des corticoïdes est
      largement documentée dans les exacerbations de
      BPCO.`,

      `En effet Docteur, l’antibiothérapie est probabiliste
      et active sur les principaux germes suspectés.`
    ],
    answer: [`Oxygénothérapie`, `Bronchodilatateurs`, `Corticostéroïdes`, `Antibiothérapie`],
    infoglobal: [
      `<div class='titre'>Oxygénothérapie</div>
    <div class='info-popup'>
    L’objectif est de maintenir une PaO2  60 mmHg et une SaO2  90% afin d’éviter une majoration
    néfaste de la capnie.<sup>[18]</sup>
    </div>

    <div class ='reference'>
    <b>PaO2 :</b> Pression partielle d'oxygène.
    <b>SaO2 :</b> Saturation artérielle en oxygène.
    <div class='sous_titre2'> Références </div>
    <b>18.</b>Rodriguez-Roisin R. COPD exacerbations. 5: management. Thorax 2006 ; 61 (6) : 535-44.
    </div>
   `

      ,
      `<div class='titre'>Hospitalisation</div>
    <div class='info-popup'> 
    <b>Critères d’hospitalisation des exacerbations de BPCO :<sup>[13]</sup></b></br>
    - Signes de gravité immédiate</br>
    - Aggravation rapide des symptômes</br>
    - Dyspnée dans les activités quotidiennes (habillage…) à l’état basal</br>
    - Absence de réponse au traitement médical initial</br>
    - Incertitude diagnostique</br>
    - Âge avancé, fragilité</br>
    - Absence de soutien à domicile</br>
    - Oxygénothérapie au long cours, ventilation assistée à domicile</br>
    - Antécédent de séjour en réanimation pour exacerbation</br>
    - Comorbidités : cardiovasculaires, alcoolisme, neurologiques, psychiatriques</br>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>15.</b>SPLF. Actualisation des recommandations de la SPLF pour la prise en charge de la BPCO. 2003 : Rev Mal Resp.
    </br>
    <b>16.</b>Schumaker GL, Epstein SK. Managing acute respiratory failure during exacerbation of chronic obstructive pulmonary disease.</br>
    Respir Care 2004 ; 49 (7) : 766-82.
    </div>
   `
      ,

      `<div class='titre'>Bronchodilatateurs</div>
    <div class='info-popup'>  
    Les bronchodilatateurs inhalés d’action courte comme les béta-2-mimétiques (salbutamol et
      terbutaline) et les anticholinergiques (ipratropium) permettent une bronchodilatation partielle.<sup>[15]</sup>
      Malgré l’absence de preuve, les sociétés savantes recommandent de débuter avec un
      béta-2-mimétique puis un anticholinergique en l’absence d’amélioration.<sup>[15]</sup>
      Les doses et leur fréquence doivent être accrues jusqu’à amélioration des symptômes. Aucune donnée
      ne supporte la nébulisation continue.<sup>[16]</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>15.</b>SPLF. Actualisation des recommandations de la SPLF pour la prise en charge de la BPCO. 2003 : Rev Mal Resp.</br>
    <b>16.</b>Schumaker GL, Epstein SK. Managing acute respiratory failure during exacerbation of chronic obstructive pulmonary disease.
    Respir Care 2004 ; 49 (7) : 766-82.
    `
      ,

      `<div class='titre'>Corticostéroïdes</div>
    <div class='info-popup'>
    Devant un tableau d’exacerbation aiguë de BPCO les corticoïdes diminuent l’inflammation des voies
    aériennes, améliorent les symptômes et le pronostic.<sup>[17]</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>17.</b>Wood-Baker RR, et al. Systemic corticosteroids for acute exacerbations of chronic obstructive pulmonary disease. Cochrane Database Syst
    Rev 2005 ; 1 : CD001288.
    </div>`,

      `<div class='titre'>Antibiothérapie</div>
    <div class='info-popup'>  
    La prescription d’antibiotiques est indiquée pour les patients atteints d’une BPCO confirmée par
spirométrie (GOLD I à IV), souffrant d’exacerbations suffisamment graves pour justifier une
consultation médicale. De plus pour la minorité des patients dont la BPCO est moyenne à sévère
(GOLD II ou plus), l’antibiothérapie devra être débutée précocement afin de diminuer le taux
d’hospitalisations et raccourcir la durée de l’exacerbation.<sup>[14]</sup>
    </div>
    <div class ='reference'>
    <b>GOLD :</b> Global Initiative for Chronic Obstructive Lung Disease.
    <div class='sous_titre2'> Références </div>
    <b>14.</b> Place de l’antibiothérapie dans la prise en charge des exacerbations de BPCO [Internet]. Revue Medicale Suisse. [En ligne, consulté le 16
      juill 2021]. Disponible sur :
     <a target="_blank" href="https://www.revmed.ch/revue-medicale-suisse/2007/revue-medicale-suisse-106/place-de-l-antibiotherapie-dans-la-prise-en-charge-desexacerbations-de-bpco">https://www.revmed.ch/revue-medicale-suisse/2007/revue-medicale-suisse-106/place-de-l-antibiotherapie-dans-la-prise-en-charge-desexacerbations-de-bpco</a>   `],

    toutref: [`<div class='titre'>Traitement</div>
    <div class='info-popup'>
    <b>Critères d’hospitalisation des exacerbations de BPCO :<sup>[13]</sup></b></br>
    - Signes de gravité immédiate</br>
    - Aggravation rapide des symptômes</br>
    - Dyspnée dans les activités quotidiennes (habillage…) à l’état basal</br>
    - Absence de réponse au traitement médical initial</br>
    - Incertitude diagnostique</br>
    - Age avancé, fragilité</br>
    - Absence de soutien à domicile</br>
    - Oxygénothérapie au long cours, ventilation assistée à domicile</br>
    - Antécédent de séjour en réanimation pour exacerbation</br>
    - Comorbidités : cardiovasculaires, alcoolisme, neurologiques, psychiatriques</br>
    </br>
    </br>
    La prescription d’antibiotiques est indiquée pour les patients atteints d’une BPCO confirmée par
    spirométrie (GOLD I à IV), souffrant d’exacerbations suffisamment graves pour justifier une
    consultation médicale. De plus pour la minorité des patients dont la BPCO est moyenne à sévère
    (GOLD II ou plus), l’antibiothérapie devra être débutée précocement afin de diminuer le taux
    d’hospitalisations et raccourcir la durée de l’exacerbation.<sup>[14]</sup>
    </br>
    </br>
    Les bronchodilatateurs inhalés d’action courte comme les béta-2-mimétiques (salbutamol et
    terbutaline) et les anticholinergiques (ipratropium) permettent une bronchodilatation partielle.[15]
    Malgré l’absence de preuve, les sociétés savantes recommandent de débuter avec un
    béta-2-mimétique puis un anticholinergique en l’absence d’amélioration.<sup>[15]</sup>
    Les doses et leur fréquence doivent être accrues jusqu’à amélioration des symptômes. Aucune donnée
    ne supporte la nébulisation continue.<sup>[16]</sup>
    </br>
    </br>
    Devant un tableau d’exacerbation aiguë de BPCO les corticoïdes diminuent l’inflammation des voies
    aériennes, améliorent les symptômes et le pronostic.<sup>[17]</sup>
    </br>
    </br>
    L’objectif est de maintenir une PaO2  60 mmHg et une SaO2  90% afin d’éviter une majoration
    néfaste de la capnie.<sup>[18]</sup>
    </div>

    <div class ='reference'>
    <b>GOLD :</b> Global Initiative for Chronic Obstructive Lung Disease.
    <div class='sous_titre2'> Références </div>
    <b>13.</b> CEP : Collège des enseignants de pneumologie. Item 205 : Bronchopneumopathie chronique obstructive (BPCO). [En ligne, consulté
      le 13/07/2021]. Disponible sur :  <a target="_blank" href="http://cep.splf.fr/wp-content/uploads/2018/09/item_205_BPCO_2018.pdf">http://cep.splf.fr/wp-content/uploads/2018/09/item_205_BPCO_2018.pdf</a>
      <b>14.</b> Place de l’antibiothérapie dans la prise en charge des exacerbations de BPCO [Internet]. Revue Medicale Suisse. [En ligne, consulté
      le 16 juill 2021]. Disponible sur :
      <a target="_blank" href="https://www.revmed.ch/revue-medicale-suisse/2007/revue-medicale-suisse-106/place-de-l-antibiotherapie-dans-la-prise-en-charge-des-exacerbations-de-bpco">https://www.revmed.ch/revue-medicale-suisse/2007/revue-medicale-suisse-106/place-de-l-antibiotherapie-dans-la-prise-en-charge-des-exacerbations-de-bpco</a>
      <b>15.</b> SPLF. Actualisation des recommandations de la SPLF pour la prise en charge de la BPCO. 2003 : Rev Mal Resp.
      <b>16.</b> Schumaker GL, Epstein SK. Managing acute respiratory failure during exacerbation of chronic obstructive pulmonary disease.
      Respir Care 2004 ; 49 (7) : 766-82.
      <b>17.</b> Wood-Baker RR, et al. Systemic corticosteroids for acute exacerbations of chronic obstructive pulmonary disease. Cochrane
      Database Syst Rev 2005 ; 1 : CD001288.
      <b>18.</b> Rodriguez-Roisin R. COPD exacerbations. 5: management. Thorax 2006 ; 61 (6) : 535-44.
    </div>
   `],
  },
  {
    id: 8,
    type: 'qcm',
    chapitre: 5,
    bg: 'medecin',
    reference: [],
    question: [`Docteur, en se basant sur le contexte et sur les
    données cliniques du patient, votre antibiothérapie
    de première intention sera à base de :`],
    suite: [],
    options: [
      `Azithromycine`,
      `Lévofloxacine`,
      `Ceftriaxone`,
      `Amoxicilline/acide clavulanique`],
    answer: [`Amoxicilline/acide clavulanique`],
    info: [
      `En fait Docteur, l’azithromycine n’est pas
    recommandée pour le traitement des
    exacerbations aiguës de BPCO.`,
      `En fait Docteur, la lévofloxacine est réservée au
    traitement des exacerbations aiguës de BPCO
    lorsqu’aucun autre antibiotique ne peut être utilisé
    à cause de ses effets indésirables.`,
      `En fait Docteur, la ceftriaxone n’est pas
    recommandée pour le traitement des
    exacerbations aiguës de BPCO.`,
      `Bravo Docteur, en effet l’amoxicilline/acide
    clavulanique est le traitement adéquat pour ce
    patient.`],
    infoglobal: [
      `<div class='titre'>Azithromycine</div> 
    <div class='info-popup'>
    Le taux de résistance de <i>S. pneumoniae</i> aux macrolides est élevé.<sup>x[20]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>20.</b> Torumkuney D, et al. Results from the Survey of Antibiotic Resistance (SOAR) 2015–18 in Tunisia, Kenya and Morocco: data
    based on CLSI, EUCAST (dose-specific) and pharmacokinetic/pharmacodynamic (PK/PD) breakpoints.
    J Antimicrob Chemother 2020; 75 Suppl 1: i2–i18.
    </div>`,

      `<div class='titre'>Lévofloxacine</div>
    L'EMA et la FDA mettent en garde contre les fluoroquinolones et leur association à des effets
indésirables graves, invalidants et potentiellement irréversibles, atteignant : les tendons, les muscles,
les articulations et le système nerveux central.<sup>[21,22]</sup>
    </div>

    <div class='reference'>
    <b>EMA :</b> European Medicines Agency.</br>
    <b>FDA :</b> Food and Drug Administration.
    <div class='sous_titre2'>Références </div> 
    <b>21.</b> FDA. FDA updates warnings for fluoroquinolone antibiotics. [En ligne, consulté le 03/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://www.fda.gov/news-events/press-announcements/fda-updates-warnings-fluoroquinolone-antibiotics">https://www.fda.gov/news-events/press-announcements/fda-updates-warnings-fluoroquinolone-antibiotics</a></br>
    <b>22.</b> European Medicines Agency. Quinolone- and fluoroquinolone-containing medicinal products. [En ligne, consulté le 03/03/2021].</br>
    Disponible sur: <a target="_blank" href="https://www.ema.europa.eu/en/medicines/human/referrals/quinolone-fluoroquinolone-containing-medicinal-products">https://www.ema.europa.eu/en/medicines/human/referrals/quinolone-fluoroquinolone-containing-medicinal-products</a>
    </div>`,


      `<div class='titre'>Ceftriaxone</div> 
    <div class='info-popup'>
    L'utilisation des céphalosporines de troisième génération par voie parentérale est limitée à certaines
situations, par exemple lorsque l'on est en face de difficultés d’administration par voie orale.<sup>[23]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>23.</b> Afssaps. Antibiothérapie par voie générale en pratique courante au cours des infections respiratoires basses de l'adulte et de l'enfant. 2005;25.
    </div>`,


      `<div class='titre'>Amoxicilline/acide clavulanique</div>
    <div class='info-popup'>
    Amoxicilline/acide clavulanique est recommandée par les guidelines GOLD 2019.<sup>[24]</sup>
    L'antibiothérapie devrait se faire sur la base des profils locaux de sensibilité.<sup>[20]</sup>
    Le GOLD recommande l'amoxicilline/acide clavulanique comme traitement antibiotique probabiliste
    initial, avec une durée de traitement recommandée de 5 à 7 jours.<sup>[24]</sup>
    L'amoxicilline/acide clavulanique est également considéré comme un traitement de premier choix par
    le comité d'experts de l'OMS pour la sélection et l'utilisation des médicaments essentiels.<sup>[25]</sup>
    L’amoxicilline/acide clavulanique présente un profil de sensibilité favorable vis à vis de Streptococcus
    pneumoniae et de Haemophilus influenzae. De plus, lors d’un essai clinique randomisé, le traitement
    en ambulatoire des exacerbations de BPCO légère à modérée avec l’amoxicilline/acide clavulanique
    a significativement prolongé le délai de survenue de la prochaine exacerbation, comparativement au
    placebo.<sup>[26]</sup>
    </div>

    <div class='reference'>
    <b>GOLD :</b> Global Initiative for Chronic Obstructive Lung Disease.
    <div class='sous_titre2'>Références </div> 
    <b>24.</b> Global Initiative for Chronic Obstructive Lung Disease (GOLD). Global strategy for the diagnosis, management and prevention of
    chronic obstructive pulmonary disease: 2020 report. [En ligne, consulté le 03/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf">https://goldcopd.org/wp-content/uploads/2019/12/GOLD-2020-FINAL-ver1.2-03Dec19_WMV.pdf</a>
    </br>
    <b>20.</b> Torumkuney D, et al. Results from the Survey of Antibiotic Resistance (SOAR) 2015–18 in Tunisia, Kenya and Morocco: data based
    on CLSI, EUCAST (dose-specific) and pharmacokinetic/pharmacodynamic (PK/PD) breakpoints.</br>
    J Antimicrob Chemother 2020; 75 Suppl 1: i2–i18.</br>
    <b>25.</b> WHO. The Selection and Use of Essential Medicines. [En ligne, consulté le 03/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://apps.who.int/iris/bitstream/handle/10665/259481/9789241210157-eng.pdf?sequence=1">https://apps.who.int/iris/bitstream/handle/10665/259481/9789241210157-eng.pdf?sequence=1</a>
    </br>
    <b>26.</b> Lior C, Moragas A, Hernández S, et al. Efficacy of antibiotic therapy for acute exacerbations of mild to moderate chronic obstructive
    pulmonary disease. Am J Respir Crit Care Med. 2012;186(8):716-23.
  
    </div>`],

    toutref: [],
  },
  {
    id: 9,
    chapitre: 6,
    type: 'qcm',
    bg: 'medecin',
    toutref: [],
    reference: [],
    question: `Nassim présente-t-il un risque de résistance aux
    antibiotiques de première ligne <span class='interogation'>?</span>`,
    suite: [],
    options: [`OUI`, `NON`,],
    answer: [`NON`],
    info: [
      `En fait docteur, Nassim ne présente aucun facteur
      de risque de résistance aux antibiotiques de
      première ligne.`,
      `En effet Docteur, Nassim ne présente aucun facteur
      de risque de résistance aux antibiotiques de
      première ligne.`,
    ],
    infoglobal: [``,
      `<div class='titre'>Les principaux facteurs de risque de résistance
      aux antibiotiques de première ligne<sup>19</sup></div> 
      <div class='info-popup'>
      - L’âge > 65 ans</br>
      - L’antibiothérapie antérieure au cours du dernier mois</br>
      - L’hospitalisation récente au cours des cinq derniers jours</br>
      - Les comorbidités</br>
      - L’immunodépression</br>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>19.</b>Chow AW, et al. IDSA clinical practice guideline for acute bacterial rhinosinusitis in children and adults.
      Clin Infect Dis. 2012;54(8):e72-e112.
      </div>
     `,
    ],
  },
  {
    id: 10,
    type: 'qcm',
    chapitre: 7,
    bg: 'medecin',
    reference: [],
    question: [`À votre avis Docteur, quelles sont les mesures à
    prendre pour prévenir la survenue d’un nouvel
    épisode d’exacerbation <span class='interogation'>?</span>`],
    suite: [`Bravo Docteur, l'arrêt du tabac, le maintien ou la
    reprise de l’activité sportive ainsi que la vaccination
    anti-grippale sont des mesures importantes pour
    prévenir la survenue de nouvelles exacerbations de
    BPCO chez Nassim.`],
    options: [
      `Maintien de l'activité physique`,
      `Sevrage tabagique`,
      `Vaccination anti-pneumococcique`,
      `Vaccination anti-grippale`],
    answer: [`Vaccination anti-grippale`, `Maintien de l'activité physique`, `Sevrage tabagique`],
    info: [
      `En effet Docteur, le faible niveau d’activité physique
    est associé à une augmentation du risque
    d’exacerbation.`,
      `En effet Docteur, l'arrêt du tabac est prioritaire dans
    la prise en charge de Nassim.`,
      `En fait, la vaccination anti-pneumococcique n’est
    pas indiquée pour Nassim.`,
      `En effet Docteur, la vaccination contre le virus de la
    grippe diminue son impact sur les exacerbations.`],
    infoglobal: [
      `<div class='titre'>Maintien de l'activité physique</div> 
    <div class='info-popup'>
    Tous les patients atteints de BPCO devraient être encouragés à faire des exercices physiques dans les
limites de toute comorbidité.<sup>[28]</sup></br>
Des études montrent qu’il est possible de réduire la sensation d’essoufflement et de diminuer le nombre
des épisodes d'exacerbations ainsi que la fréquence des hospitalisations grâce à un ensemble de
moyens dont l’activité physique.<sup>[29]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>28.</b> Gruffydd-Jones K. PCRS UK, 2016; pp. 20–7.</br>
    <b>29.</b> SPLF. Les Bienfaits de l’Activité Physique. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://splf.fr/wp-content/uploads/2018/04/J6_Vasseur.pdf">https://splf.fr/wp-content/uploads/2018/04/J6_Vasseur.pdf</a>
    
   
    </div>`,

      `<div class='titre'>Sevrage tabagique</div>
    <div class='info-popup'>
    Les bénéfices du sevrage tabagique sont nombreux : arrêt de la progression de l’obstruction
bronchique, report de l’apparition de l’insuffisance respiratoire, prévention et contrôle des symptômes,
réduction de la fréquence et de la sévérité des exacerbations, amélioration de la qualité de vie et de
la tolérance à l’effort et à l’exercice. D’où la nécessité d’intervenir à chaque occasion pour favoriser et
maintenir le sevrage tabagique.<sup>[30]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>30.</b> HAS. BPCO : diagnostic et prise en charge. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://www.has-sante.fr/jcms/p_3118947/fr/bpco-sevrage-tabagique-et-rehabilitation-respiratoire">https://www.has-sante.fr/jcms/p_3118947/fr/bpco-sevrage-tabagique-et-rehabilitation-respiratoire</a>
    </div>`,

      `<div class='titre'>Vaccination anti-pneumococcique</div> 
    <div class='info-popup'>
    La vaccination anti-pneumococcique est recommandée pour les patients âgés de plus de 65 ans ou
présentant une comorbidité.<sup>[27]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>27.</b> GSK. La BPCO. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf">https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf</a>
   
    </div>`,

      `<div class='titre'>Vaccination anti-grippale</div>
    <div class='info-popup'>
    La vaccination contre la grippe est recommandée pour tous les patients atteints de BPCO.<sup>[27]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>27.</b>GSK. La BPCO. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf">https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf</a>
    </div>`],

    toutref: [`
    <div class='titre'>Traitement</div>
    <div class='info-popup'>
    La vaccination contre la grippe est recommandée pour tous les patients atteints de BPCO.<sup>[27]</sup>
    </br>
    </br>
Tous les patients atteints de BPCO devraient être encouragés à faire des exercices physiques dans les
limites de toute comorbidité.<sup>[28]</sup></br>
Des études montrent qu’il est possible de réduire la sensation d’essoufflement et de diminuer le nombre
des épisodes d'exacerbations ainsi que la fréquence des hospitalisations grâce à un ensemble de
moyens dont l’activité physique.<sup>[29]</sup>
</br>
</br>
Les bénéfices du sevrage tabagique sont nombreux : arrêt de la progression de l’obstruction
bronchique, report de l’apparition de l’insuffisance respiratoire, prévention et contrôle des symptômes,
réduction de la fréquence et de la sévérité des exacerbations, amélioration de la qualité de vie et de
la tolérance à l’effort et à l’exercice. D’où la nécessité d’intervenir à chaque occasion pour favoriser et
maintenir le sevrage tabagique.<sup>[30]</sup></br>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>27.</b> GSK. La BPCO. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur : <a target="_blank" href="https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf">https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf</a>
    </br>
    <b>28.</b> Gruffydd-Jones K. PCRS UK, 2016; pp. 20–7.</br>
    <b>29.</b> SPLF. Les Bienfaits de l’Activité Physique. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://splf.fr/wp-content/uploads/2018/04/J6_Vasseur.pdf">https://splf.fr/wp-content/uploads/2018/04/J6_Vasseur.pdf</a>
    </br>
   <b>30.</b> HAS. BPCO : diagnostic et prise en charge. [en ligne, Consulté le 18/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.has-sante.fr/jcms/p_3118947/fr/bpco-sevrage-tabagique-et-rehabilitation-respiratoire">https://www.has-sante.fr/jcms/p_3118947/fr/bpco-sevrage-tabagique-et-rehabilitation-respiratoire</a>
       
    </div>
    `],
  },
  {
    id: 11,
    type: 'qcm',
    //isInfo : true si il y a info
    rejouer: true,
    chapitre: 7,
    bg: 'medecin',
    reference: [],
    question: [`Cinq jours plus tard, Nassim revient pour un
    contrôle. Son médecin l’examine puis évalue son
    observance thérapeutique et sa technique
    d’utilisation de l’inhalateur. La majorité des
    patients (jusqu’à 70-80%) n’utilise pas l’inhalateur
    correctement. Ceci contribue à un faible contrôle
    des symptômes et à la survenue d’exacerbations.<sup>1</sup>
    Le niveau de satisfaction des patients vis-à-vis d’un
    dispositif d’inhalation est corrélé à une meilleure
    observance et à une réduction des exacerbations
    chez la majorité des patients.<sup>2</sup> Le médecin de
    Nassim lui prescrit ensuite un vaccin-anti grippal.<sup>3</sup>`],
    suite: [`La vaccination est un axe important dans la lutte
    contre l'antibiorésistance. En effet, qu'elle concerne
    des bactéries ou des virus pouvant conduire à une
    surinfection bactérienne, elle permet de réduire
    l'exposition aux antibiotiques et donc de prévenir
    l'émergence et la sélection de souches résistantes.<sup>1</sup>`,
      `Bravo Docteur ! Vous avez terminé le cas clinique
  brillamment. Grâce à vous et votre expertise,
  Nassim se porte bien.`],
    options: [],
    answer: [],
    info: [],
    infoglobal: [`
    <div class='titre'>Références</div>
    <div class='info-popup'>
    <b>31.</b> GINA. Global strategy for asthma management and prevention. Updated 2019.</br>
    <b>32.</b> Small M et al. Importance of inhaler-device satisfaction in asthma treatment:</br>
    real-world observations of physician-observed compliance and clinical/patient-reported outcomes.</br>
    Adv Ther 2011 ;28(3) :202-212.</br>
    <b>27.</b> GSK. La BPCO. [en ligne, Consulté le 18/03/2021].Disponible sur :</br>
    <a target="_blank" href="https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf">https://gskpro.com/content/dam/global/hcpportal/fr_DZ/PDfs/therapy/module_2_bpco.pdf</a>
    </div>`,

      `<div class='titre'>Références</div>
    <div class='info-popup'>
    <b>33.</b>HAL. Antibiotiques et résistances : enquête sur les connaissances et les comportements du grand</br>
    public. [en ligne, Consulté le 24/03/2021].</br>
    Disponible sur :  <a target="_blank" href="https://hal.univ-lorraine.fr/hal-01734015/document">https://hal.univ-lorraine.fr/hal-01734015/document</a>
    </div>`,
      ``
    ],
    adresse: [
      `
      <div>
        
          <div>
      <div class='sous_titre2'> <b>Pour toutes informations complémentaires, veuillez contacter GlaxoSmithKline Tunisie :</b> </div>
      <div class="text">
     Immeuble Khairi- New Tower, Lot B17, Chez Regus (7<sup>ème</sup> étage), Centre Urbain Nord 1080. Tunis. Tunisie. </br>
      <span class='sous_titre2'>Tél.:</span> +216 71 134 534 ;<span class='sous_titre2'>Fax :</span> +216 71 134 599
      </div>
     
          </div>
     
          <div>
      <div class='sous_titre2'>  <b>Pour toute notification des effets indésirables, veuillez nous contacter par e-mail à l'adresse suivante :</b> </div>
     <div class="text">
      <a href="pharmacovigilance.na@gsk.com">pharmacovigilance.na@gsk.com</a> ou via <a href="www.gskpro.com/fr-tn">www.gskpro.com/fr-tn</a> en cliquant sur « Signaler un effet indésirable »
      </div>
      </div>
        </div>
        <div class="text reg">
        PM-TN-ACA-WCNT-210004 Date de préparation : Août 2022
  </div>
  
        `],
    toutref: [],
  },

];



export const FicheData = [
  {
    type: "fiche1",
    vid: 1,
    fiche: ["<li>Nassim, 45 ans </li><li>Exposition à des émanations irritantes</li><li>Fumeur</li>"],
  },
  {
    type: "fiche1",
    vid: 2,
    fiche: ["<li>Pas d’antécédents familiaux d’asthme ou d’allergie.</li>"],
  },
  {
    type: "fiche1",
    vid: 3,
    fiche: ["<li>Dyspnée d’effort</li>"],
  }
  ,
  {
    type: "fiche1",
    vid: 4,
    fiche: ["<li>Toux</li><li>Expectorations</li>"],
  },

  {
    type: "fiche2",
    vid: 5,
    fiche: [
      `<li>Signe de Campbell, respiration à lèvres pincées</li>`,
      `<li>Bicarbonates légèrement élevés</li>`,
      `<li>Signe de distension thoracique</li>`,
      `<li>VEMS=45%</li><li>VEMS/CVF=0,6</li>`,
      `<li>Râles sibilants</li>`,

    ],
  }
  ,
  {
    type: "fiche2",
    vid: 6,
    fiche: [`<span class='fiche4_gris fleche'>
    Exacerbation aiguë de BPCO</span>
    <div class='reference dossier'>
    
    <b>VEMS :</b>Volume expiratoire maximal par seconde.</br>
    <b>CVF :</b>Capacité vitale forcée.</br>
    <b>BPCO :</b>Bronchopneumopathie chronique obstructive.
    </br>
   
    </div>`],

  },
  {
    type: "fiche3",
    vid: 8,
    fiche: [
      "<li>Streptococcus pneumoniae</li>",
      "<li>Haemophilus influenzae</li>",
      "<li>Moraxella catarrhalis</li>",
      ""],
  },

  {
    vid: 10,
    type: "fiche4",
    fiche: [
      "<li>Oxygénation</li>",
      "",
      "<li>Bronchodilatateurs</li>",
      "<li>Corticostéroïdes</li>",
      "<li>Antibiothérapie</li>"],
  },
  {
    vid: 13,
    type: "fiche4",
    fiche: [
      "",
      "",
      "",
      "<li>Amoxicilline/acide clavulanique</li>",
    ],
  }
]








import React, {Component} from 'react';
import Popup from './Popup';  
import { QuizData } from './QuizData';

class Info extends Component {  

  constructor(props){  
    super(props);  
    this.state = { 
      showPopup: false,
      infoglobal: props.infoglobal,
      comptinfo: props.comptinfo,
      index:props.index,
      toutref:props.toutref,
      questionId:props.questionId, 
      answers:props.answers,
      incrementation:props.incrementation

    };  
  }  

  togglePopup() {  
    
     if(
         ((this.state.infoglobal.length!=0 || this.state.toutref!='')
            &&  (this.state.comptinfo>-1 && this.state.infoglobal[this.state.comptinfo]!='')
           ||  ((QuizData[this.state.questionId].rejouer || QuizData[this.state.questionId].isInfo) && this.state.infoglobal[this.props.incrementation]!="")
           
           
         )
       ) 
       {
      
        this.setState({  
          showPopup: !this.state.showPopup  
        });  
       }else {console.log(this.state.infoglobal.length);}
  }  
  verif() { 
      if( (this.state.infoglobal.length!=0) || (this.state.toutref!='') && (this.state.comptinfo>-1) && (this.state.infoglobal[this.state.comptinfo]!=''))
      {
                  var el1 =document.getElementsByClassName('info');
                  el1[0].classList.add('info_full');
                  el1[0].style.display = "block";
                  
                }
      else{
        
        var el1 =document.getElementsByClassName('info');
        el1[0].style.opacity = "0.5";
        el1[0].classList.remove('info_full');
        
      }
      // if(QuizData[this.state.questionId].rejouer && QuizData[this.state.questionId].suite.length === this.props.incrementation) {
      //   var el1 =document.getElementsByClassName('info');
      //   el1[0].style.opacity = "0";
      //   el1[0].classList.remove('info_full');
      // }
    
  }   
  componentDidUpdate(){
    
     this.verif()
    // if ((this.state.questionId==4)||&&(this.state.comptinfo>-1))
    //   {
    //   var el =document.getElementsByClassName('info');
    
    //    el[0].classList.add('info_full');

    //   } 

  }
  componentWillReceiveProps(props) {
        this.setState({infoglobal: props.infoglobal});
        this.setState({comptinfo: props.comptinfo});
        this.setState({index: props.index}); 
        this.setState({toutref: props.toutref}); 
        this.setState({questionId: props.questionId}); 
        this.setState({answers: props.answers}); 
        this.setState({incrementation: props.incrementation}); 
  }

render() {  
  return (  
    
        <div> 
      
          <div className = "info"
              onClick={this.togglePopup.bind(this)}> 
          </div>  
     
          {(this.state.index===true && this.state.toutref.length !== 0 
          //&& this.state.answers.length > 1 (afficher tou ref)
          )
         ?
            <div> 
                {this.state.showPopup &&
                  <Popup  
                      infoglobal={this.state.toutref} 
                      closePopup={this.togglePopup.bind(this)}/>
                }
            </div>
          :
            <div>
                {this.state.showPopup &&  
                  <Popup  
                        infoglobal={(QuizData[this.state.questionId].rejouer ||QuizData[this.state.questionId].isInfo) ? this.state.infoglobal[this.props.incrementation] :this.state.infoglobal[this.state.comptinfo]}
                        closePopup={this.togglePopup.bind(this)}
                        />
                }
            </div>
          }  
      </div>  

  );  
}  
}  
export default Info;